import React from "react"
import axios from "axios"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import { graphql, useStaticQuery } from "gatsby"

function ContactPage() {
  const { allPrismicContact } = useStaticQuery(graphql`
    query ContactPageQuery {
      allPrismicContact {
        edges {
          node {
            data {
              slug
              subtitle {
                text
              }
            }
          }
        }
      }
    }
  `)

  const {
    subtitle,
  } = allPrismicContact.edges[0].node.data

  console.log('subtitle', subtitle);
  const [formValues, setformValues] = React.useState({
    firstName: "",
    email: "",
    subject: "",
    message: "",
  })
  const [showConfirmation, setShowConfirmation] = React.useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const contactFormData = {
      firstName: formValues.firstName,
      email: formValues.email,
      message: formValues.message,
      subject: formValues.subject,
    }
    
    try {
      await axios({
        method: "post",
        url: `${process.env.GATSBY_LATNERN_REST_URL}/contactUs`,
        data: contactFormData,
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        setShowConfirmation(true)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => {
    setformValues({
      ...formValues,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Layout>
      <SEO
        keywords={["Lantern", "contact"]}
        title="Contact"
        description="Contact Lantern."
        excludeSiteMetadataTitle
      />
      <div className="bg-grey-lighter">
        <div className="max-w-body mx-auto pt-16 md:pt-24 px-4 xl:px-0">
          <div className="headline-text text-center">Contact Us</div>

          {showConfirmation && (
            <div className="sub-headline-text leading-relaxed text-center pt-8">
              Thank you for your message!
            </div>
          )}

          <div className="sub-headline-text leading-relaxed text-center pt-8">
            {subtitle.text}
          </div>

          <section className="md:flex md:items-center py-16">
            {!showConfirmation && (
              <form
                className="mx-auto lg:w-1/2"
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="flex flex-wrap">
                  <div className="w-full">
                    <label
                      className="text-grey-dark text-sm font-semibold w-full"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="firstName"
                      name="firstName"
                      placeholder="Jane"
                      value={formValues.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="text-grey-dark text-sm font-semibold w-full"
                      htmlFor="email"
                    >
                      Your Email Address
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="email"
                      name="email"
                      placeholder="yourname@website.com"
                      type="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="text-grey-dark text-sm font-semibold w-full"
                      htmlFor="subject"
                    >
                      Subject
                    </label>
                    <input
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="subject"
                      name="subject"
                      placeholder="I need help adding a task"
                      value={formValues.subject}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      className="text-grey-dark text-sm font-semibold w-full"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      className="default-form-field w-full mb-4 py-3 border-grey"
                      id="message"
                      name="message"
                      placeholder="What would you like to tell us?"
                      rows="8"
                      value={formValues.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="md:flex">
                  <Button
                    buttonText="Send"
                    buttonType="btn in-app -primary"
                    additionalClasses="ml-0"
                    eventName={{
                      eventDomain: "App",
                      eventLocation: "Contact Form",
                      eventAction: "Click",
                      eventActionName: "Send",
                    }}
                  />
                </div>

                <div className="tracking-normal leading-normal font-normal text-sm pb-2 text-grey-darker text-left pt-4">
                  <p>
                    For press inquiries, email{" "}
                    <strong>
                      <a
                        href="mailto:media@lantern.co"
                        target="undefined"
                        rel="noopener noreferrer"
                      >
                        media@lantern.co
                      </a>
                    </strong>
                  </p>
                </div>
              </form>
            )}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
